<template>
  <div class="container">
    <h2>{{ editMode ? "Edit" : "Add" }} OwnerServicesBanners</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>

    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>

    <button
      @click="deleteOwnerServicesBanners()"
      type="button"
      class="btn btn-danger"
    >
      Delete
    </button>

    <div class="row">
      <div class="col-md-6">
        <label class for="Heading">Heading</label>
        <input
          class="form-control"
          id="Heading"
          name="Heading"
          v-model="editingOwnerServicesBanners.Heading"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-6">
        <label class for="Copy">Copy</label>
        <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="editingOwnerServicesBanners.Copy"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ImageUploader
          title="Image"
          v-model="editingOwnerServicesBanners.Image"
             
          @ImageUploaded="imageUploaded"
        />
      </div>
      <div class="col-md-6">
        <ImageUploader
          title="Image"
          v-model="editingOwnerServicesBanners.MobileImage"
          @ImageUploaded="imageUploadedMobile"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <label class for="Link">Link</label>
        <input
          class="form-control"
          id="Link"
          name="Link"
          v-model="editingOwnerServicesBanners.Link"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-3">
        <label class for="LinkFriendly">LinkFriendly</label>
        <input
          class="form-control"
          id="LinkFriendly"
          name="LinkFriendly"
          v-model="editingOwnerServicesBanners.LinkFriendly"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-3">
        <label class for="LastUpdated">LastUpdated</label>
        <input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="editingOwnerServicesBanners.LastUpdated"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>

      <div class="col-md-3">
        <label class="" for="ShowText">ShowText</label>
        <br />
        <b-form-checkbox
          v-model="editingOwnerServicesBanners.ShowText"
        ></b-form-checkbox>
        <div class=""><small></small></div>
      </div>
    </div>

    <br />
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import ImageUploader from "@/components/custom/ImageUploader.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

export default {
  name: "EditOwnerServicesBanners",

  data() {
    return {
      imageBytes: {},
      selectedFile: {},
      originalData: {},
      modifiedData: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false,
    };
  },
  components: {
    ImageUploader,
  },
  created() {
    this.originalData = JSON.parse(
      JSON.stringify(this.editingOwnerServicesBanners)
    );
    this.ImageBytes = this.editingOwnerServicesBanners.Image;
  },

  props: ["editingOwnerServicesBanners", "editMode"],

  methods: {
    toggleView: function () {
      this.uploadedImageUrl = null;

      if (this.editMode) {
        this.$emit("closeEditOwnerServicesBanners");
      } else {
        this.$emit("closeAddOwnerServicesBanners");
      }
    },

    save: function () {
      var data = JSON.parse(JSON.stringify(this.editingOwnerServicesBanners));
      var payload = {
        data: data,
        image: this.uploadedImageData,
        success: (response) => {
          if (this.editMode) {
            this.$emit("editOwnerServicesBannersSuccess");
          } else {
            this.$emit("addOwnerServicesBannersSuccess");
          }
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      if (this.editMode) {
        this.$store.dispatch("editOwnerServicesBanners", payload);
      } else {
        this.$store.dispatch("addOwnerServicesBanners", payload);
      }
    },
    imageUploaded: function (file) {
      this.editingOwnerServicesBanners.Image = file;
    },
    imageUploadedMobile: function (file) {
      this.editingOwnerServicesBanners.MobileImage = file;
    },
    deleteOwnerServicesBanners: function () {
      var payload = {
        data: this.editingOwnerServicesBanners,
        success: (response) => {
          this.$emit("editOwnerServicesBannersSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("deleteOwnerServicesBanners", payload);
    },
  },
};
</script> 
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 

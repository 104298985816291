<template>
  <div class="card">
    <div class="container">
      <b-modal ref="editOwnerServicesBannersModal" hide-footer>
        <editOwnerServicesBanners
          :editMode="editMode"
          :editingOwnerServicesBanners="currentOwnerServicesBanners"
          @closeEditOwnerServicesBanners="toggleEditOwnerServicesBanners()"
          @editOwnerServicesBannersSuccess="editOwnerServicesBannersSuccess()"
          @closeAddOwnerServicesBanners="toggleAddOwnerServicesBanners()"
          @addOwnerServicesBannersSuccess="addOwnerServicesBannersSuccess()"
        ></editOwnerServicesBanners>
      </b-modal>
      <div class="row">
        <h2>OwnerServicesBanners</h2>
      </div>
      <div class="row">
        <button
          @click="toggleAddOwnerServicesBanners()"
          type="button"
          class="btn btn-success"
        >
          Add
        </button>
      </div>
      <div class="row">
        <br />
        <datatable
          :dataItems="items"
          @rowClicked="editItem"
          :dataColumns="columns"
          :searching="true"
          :sorting="true"
          @editRow="editItem"
          :order="[1, 'dec']"
          class="text-center"
          striped
          bordered
        />
      </div>
    </div>
  </div>
</template>
<script>
import addOwnerServicesBanners from "../../components/addOwnerServicesBanners";
import editOwnerServicesBanners from "../../components/editOwnerServicesBanners";
import VueNotifications from "vue-notifications";
import { Datatable } from "../../mdb/components/Datatable";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
        {
          label: "Id",
          field: "Id",
          sort: "asc",
        },
        {
          label: "Heading",
          field: "Heading",
          sort: "asc",
        },
        {
          label: "Copy",
          field: "Copy",
          sort: "asc",
        },
        {
          label: "Image",
          field: "Image",
          sort: "asc",
        },
        {
          label: "Link",
          field: "Link",
          sort: "asc",
        },
        {
          label: "LinkFriendly",
          field: "LinkFriendly",
          sort: "asc",
        },
        {
          label: "LastUpdated",
          field: "LastUpdated",
          sort: "asc",
        },
        {
          label: "ShowText",
          field: "ShowText",
          sort: "asc",
        },
      ],
      items: [],
      addOwnerServicesBannersOpen: false,
      editOwnerServicesBannersOpen: false,
      currentOwnerServicesBanners: {},
      editMode: false,
    };
  },
  components: {
    addOwnerServicesBanners,
    editOwnerServicesBanners,
    Datatable,
  },
  created: function () {
    this.getOwnerServicesBanners();
    this.addOwnerServicesBannersOpen = false;
    this.editOwnerServicesBannersOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    getOwnerServicesBanners() {
      var payload = {
        success: (response) => {
          this.items = response.data;
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getOwnerServicesBanners", payload);
    },
    editItem(OwnerServicesBanners) {
      this.editMode = true;
      this.toggleEditOwnerServicesBanners();
      this.currentOwnerServicesBanners = OwnerServicesBanners;
    },
    toggleAddOwnerServicesBanners() {
      this.editMode = false;
      this.currentOwnerServicesBanners = {};
      if (this.editOwnerServicesBannersOpen) {
        this.$refs.editOwnerServicesBannersModal.hide();
      } else {
        this.$refs.editOwnerServicesBannersModal.show();
      }
      this.editOwnerServicesBannersOpen = !this.editOwnerServicesBannersOpen;
    },
    addOwnerServicesBannersSuccess() {
      this.toggleAddOwnerServicesBanners();
      miniToastr["success"](
        "OwnerServicesBanners Added",
        "Success",
        1000,
        null
      );
      this.getOwnerServicesBanners();
    },
    toggleEditOwnerServicesBanners() {
      this.editMode = true;
      if (this.editOwnerServicesBannersOpen) {
        this.$refs.editOwnerServicesBannersModal.hide();
      } else {
        this.$refs.editOwnerServicesBannersModal.show();
      }
      this.editOwnerServicesBannersOpen = !this.editOwnerServicesBannersOpen;
    },
    editOwnerServicesBannersSuccess() {
      this.toggleEditOwnerServicesBanners();
      miniToastr["success"](
        "OwnerServicesBanners Edited",
        "Success",
        1000,
        null
      );
      this.getOwnerServicesBanners();
    },
  },
};
</script>
<style scoped>
.OwnerServicesBannersThumbnail {
  height: 50px;
}
</style>
